export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_VERIFIED_SUCCESS = "SIGN_UP_VERIFIED_SUCCESS";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const FETCH_PROJECTS_SUCCESS = "FETCH_PROJECTS_SUCCESS";
export const FETCH_PROJECT_SUCCESS = "FETCH_PROJECT_SUCCESS";
export const EDIT_PROJECT_SUCCESS = "EDIT_PROJECT_SUCCESS";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const FETCH_PROJECTS_BY_KILN_REQUEST = "FETCH_PROJECTS_BY_KILN_REQUEST";
export const FETCH_PROJECTS_BY_KILN_SUCCESS = "FETCH_PROJECTS_BY_KILN_SUCCESS";
export const FETCH_PROJECTS_BY_KILN_FAILURE = "FETCH_PROJECTS_BY_KILN_FAILURE";

export const CREATE_KILN_SUCCESS = "CREATE_KILN_SUCCESS";
export const FETCH_KILNS_SUCCESS = "FETCH_KILNS_SUCCESS";
export const FETCH_KILN_SUCCESS = "FETCH_KILN_SUCCESS";
export const EDIT_KILN_SUCCESS = "EDIT_KILN_SUCCESS";
export const DELETE_KILN_SUCCESS = "DELETE_KILN_SUCCESS";

export const CREATE_FIRING_SUCCESS = "CREATE_FIRING_SUCCESS";
export const FETCH_FIRINGS_SUCCESS = "FETCH_FIRINGS_SUCCESSS";
export const FETCH_FIRING_SUCCESS = "FETCH_FIRING_SUCCESS";
export const FETCH_FIRINGS_BY_PROJECT_SUCCESS =
  "FETCH_FIRINGS_BY_PROJECT_SUCCESS";
export const FETCH_FIRINGS_BY_FAVOURITE_SUCCESS =
  "FETCH_FIRINGS_BY_FAVOURITE_SUCCESS";
export const EDIT_FIRING_SUCCESS = "EDIT_FIRING_SUCCESS";
export const DELETE_FIRING_SUCCESS = "DELETE_FIRING_SUCCESS";

export const CREATE_SEGMENT_SUCCESS = "CREATE_SEGMENT_SUCCESS";
export const FETCH_SEGMENTS_SUCCESS = "FETCH_SEGMENTS_SUCCESS";
export const FETCH_SEGMENT_SUCCESS = "FETCH_SEGMENT_SUCCESS";
export const FETCH_SEGMENTS_BY_PROJECT_SUCCESS =
  "FETCH_SEGMENTS_BY_PROJECT_SUCCESS";
export const FETCH_SEGMENTS_BY_FIRING_SUCCESS =
  "FETCH_SEGMENTS_BY_FIRING_SUCCESS";
export const EDIT_SEGMENT_SUCCESS = "EDIT_SEGMENT_SUCCESS";
export const DELETE_SEGMENT_SUCCESS = "DELETE_SEGMENT_SUCCESS";

export const SIGN_IN_REQUEST = "SIGN_IN_REQUEST";
export const SIGN_OUT_REQUEST = "SIGN_OUT_REQUEST";
export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const SIGN_UP_VERIFIED_REQUEST = "SIGN_UP_VERIFIED_REQUEST";
export const CREATE_PROJECT_REQUEST = "CREATE_PROJECT_REQUEST";
export const FETCH_PROJECTS_REQUEST = "FETCH_PROJECTS_REQUEST";
export const FETCH_PROJECT_REQUEST = "FETCH_PROJECT_REQUEST";
export const EDIT_PROJECT_REQUEST = "EDIT_PROJECT_REQUEST";
export const DELETE_PROJECT_REQUEST = "DELETE_PROJECT_REQUEST";

export const CREATE_KILN_REQUEST = "CREATE_KILN_REQUEST";
export const FETCH_KILNS_REQUEST = "FETCH_KILNS_REQUEST";
export const FETCH_KILN_REQUEST = "FETCH_KILN_REQUEST";
export const EDIT_KILN_REQUEST = "EDIT_KILN_REQUEST";
export const DELETE_KILN_REQUEST = "DELETE_KILN_REQUEST";

export const CREATE_FIRING_REQUEST = "CREATE_FIRING_REQUEST";
export const FETCH_FIRINGS_REQUEST = "FETCH_FIRING_REQUESTS";
export const FETCH_FIRING_REQUEST = "FETCH_FIRING_REQUEST";
export const FETCH_FIRINGS_BY_PROJECT_REQUEST =
  "FETCH_FIRINGS_BY_PROJECT_REQUEST";
export const FETCH_FIRINGS_BY_FAVOURITE_REQUEST =
  "FETCH_FIRINGS_BY_FAVOURITE_REQUEST";
export const EDIT_FIRING_REQUEST = "EDIT_FIRING_REQUEST";
export const DELETE_FIRING_REQUEST = "DELETE_FIRING_REQUEST";

export const CREATE_SEGMENT_REQUEST = "CREATE_SEGMENT_REQUEST";
export const FETCH_SEGMENTS_REQUEST = "FETCH_SEGMENTS_REQUEST";
export const FETCH_SEGMENT_REQUEST = "FETCH_SEGMENT_REQUEST";
export const FETCH_SEGMENTS_BY_PROJECT_REQUEST =
  "FETCH_SEGMENTS_BY_PROJECT_REQUEST";
export const FETCH_SEGMENTS_BY_FIRING_REQUEST =
  "FETCH_SEGMENTS_BY_FIRING_REQUEST";
export const EDIT_SEGMENT_REQUEST = "EDIT_SEGMENT_REQUEST";
export const DELETE_SEGMENT_REQUEST = "DELETE_SEGMENT_REQUEST";

export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";
export const SIGN_OUT_FAILURE = "SIGN_OUT_FAILURE";
export const SIGN_UP_FAILURE = "SIGN_UP_FAILURE";
export const SIGN_UP_VERIFIED_FAILURE = "SIGN_UP_VERIFIED_FAILURE";
export const CREATE_PROJECT_FAILURE = "CREATE_PROJECT_FAILURE";
export const FETCH_PROJECTS_FAILURE = "FETCH_PROJECTS_FAILURE";
export const FETCH_PROJECT_FAILURE = "FETCH_PROJECT_FAILURE";
export const EDIT_PROJECT_FAILURE = "EDIT_PROJECT_FAILURE";
export const DELETE_PROJECT_FAILURE = "DELETE_PROJECT_FAILURE";

export const CREATE_KILN_FAILURE = "CREATE_KILN_FAILURE";
export const FETCH_KILNS_FAILURE = "FETCH_KILNS_FAILURE";
export const FETCH_KILN_FAILURE = "FETCH_KILN_FAILURE";
export const EDIT_KILN_FAILURE = "EDIT_KILN_FAILURE";
export const DELETE_KILN_FAILURE = "DELETE_KILN_FAILURE";

export const CREATE_FIRING_FAILURE = "CREATE_FIRING_FAILURE";
export const FETCH_FIRING_FAILURES = "FETCH_FIRING_FAILURES";
export const FETCH_FIRING_FAILURE = "FETCH_FIRING_FAILURE";
export const FETCH_FIRINGS_BY_PROJECT_FAILURE =
  "FETCH_FIRINGS_BY_PROJECT_FAILURE";
export const FETCH_FIRINGS_BY_FAVOURITE_FAILURE =
  "FETCH_FIRINGS_BY_FAVOURITE_FAILURE";
export const EDIT_FIRING_FAILURE = "EDIT_FIRING_FAILURE";
export const DELETE_FIRING_FAILURE = "DELETE_FIRING_FAILURE";

export const CREATE_SEGMENT_FAILURE = "CREATE_SEGMENT_FAILURE";
export const FETCH_SEGMENTS_FAILURE = "FETCH_SEGMENTS_FAILURE";
export const FETCH_SEGMENT_FAILURE = "FETCH_SEGMENT_FAILURE";
export const FETCH_SEGMENTS_BY_PROJECT_FAILURE =
  "FETCH_SEGMENTS_BY_PROJECT_FAILURE";
export const FETCH_SEGMENTS_BY_FIRING_FAILURE =
  "FETCH_SEGMENTS_BY_FIRING_FAILURE";
export const EDIT_SEGMENT_FAILURE = "EDIT_SEGMENT_FAILURE";
export const DELETE_SEGMENT_FAILURE = "DELETE_SEGMENT_FAILURE";

export const ALERT_SUCCESS = "ALERT_SUCCESS";
export const ALERT_REQUEST = "ALERT_REQUEST";
export const ALERT_ERROR = "ALERT_ERROR";
export const ALERT_CLEAR = "ALERT_CLEAR";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const EDIT_PREFERENCES_REQUEST = "EDIT_PREFERENCES_REQUEST";
export const EDIT_PREFERENCES_SUCCESS = "EDIT_PREFERENCES_SUCCESS";
export const FETCH_PREFERENCES_REQUEST = "FETCH_PREFERENCES_REQUEST";
export const FETCH_PREFERENCES_SUCCESS = "FETCH_PREFERENCES_SUCCESS";

export const EDIT_USERDATA_REQUEST = "EDIT_USERDATA_REQUEST";
export const EDIT_USERDATA_SUCCESS = "EDIT_USERDATA_SUCCESS";
export const EDIT_FAKEUID_SUCCESS = "EDIT_FAKEUID_SUCCESS";

export const FETCH_BARTLETT_STATUS_REQUEST = "FETCH_BARTLETT_STATUS_REQUEST";
export const FETCH_BARTLETT_STATUS_SUCCESS = "FETCH_BARTLETT_STATUS_SUCCESS";

export const BARTLETT_SIGN_IN_SUCCESS = "BARTLETT_SIGN_IN_SUCCESS";

export const CREATE_SHARED_PROJECT_REQUEST = "CREATE_SHARED_PROJECT_REQUEST";
export const CREATE_SHARED_PROJECT_SUCCESS = "CREATE_SHARED_PROJECT_SUCCESS";
export const EDIT_SHARED_PROJECT_REQUEST = "EDIT_SHARED_PROJECT_REQUEST";
export const EDIT_SHARED_PROJECT_SUCCESS = "EDIT_SHARED_PROJECT_SUCCESS";

export const FETCH_SHARED_PROJECT_REQUEST = "FETCH_SHARED_PROJECT_REQUEST";
export const FETCH_SHARED_PROJECT_SUCCESS = "FETCH_SHARED_PROJECT_SUCCESS";

export const FETCH_SHARED_PROJECTS_REQUEST = "FETCH_SHARED_PROJECTS_REQUEST";
export const FETCH_SHARED_PROJECTS_SUCCESS = "FETCH_SHARED_PROJECTS_SUCCESS";

export const CREATE_SHARED_FIRING_REQUEST = "CREATE_SHARED_FIRING_REQUEST";
export const CREATE_SHARED_FIRING_SUCCESS = "CREATE_SHARED_FIRING_SUCCESS";
export const EDIT_SHARED_FIRING_REQUEST = "EDIT_SHARED_FIRING_REQUEST";
export const EDIT_SHARED_FIRING_SUCCESS = "EDIT_SHARED_FIRING_SUCCESS";

export const FETCH_SHARED_FIRING_REQUEST = "FETCH_SHARED_FIRING_REQUEST";
export const FETCH_SHARED_FIRING_SUCCESS = "FETCH_SHARED_FIRING_SUCCESS";

export const FETCH_SHARED_FIRINGS_REQUEST = "FETCH_SHARED_FIRINGS_REQUEST";
export const FETCH_SHARED_FIRINGS_SUCCESS = "FETCH_SHARED_FIRINGS_SUCCESS";

export const CREATE_SHARED_SEGMENT_REQUEST = "CREATE_SHARED_SEGMENT_REQUEST";
export const CREATE_SHARED_SEGMENT_SUCCESS = "CREATE_SHARED_SEGMENT_SUCCESS";
export const EDIT_SHARED_SEGMENT_REQUEST = "EDIT_SHARED_SEGMENT_REQUEST";
export const EDIT_SHARED_SEGMENT_SUCCESS = "EDIT_SHARED_SEGMENT_SUCCESS";

export const FETCH_SHARED_SEGMENT_REQUEST = "FETCH_SHARED_SEGMENT_REQUEST";
export const FETCH_SHARED_SEGMENT_SUCCESS = "FETCH_SHARED_SEGMENT_SUCCESS";

export const FETCH_SHARED_SEGMENTS_REQUEST = "FETCH_SHARED_SEGMENTS_REQUEST";
export const FETCH_SHARED_SEGMENTS_SUCCESS = "FETCH_SHARED_SEGMENTS_SUCCESS";

export const FETCH_SHARED_SEGMENTS_BY_FIRING_REQUEST =
  "FETCH_SHARED_SEGMENTS_BY_FIRING_REQUEST";
export const FETCH_SHARED_SEGMENTS_BY_FIRING_SUCCESS =
  "FETCH_SHARED_SEGMENTS_BY_FIRING_SUCCESS";

export const CREATE_INVENTORY_ITEM_REQUEST = "CREATE_INVENTORY_ITEM_REQUEST";
export const CREATE_INVENTORY_ITEM_SUCCESS = "CREATE_INVENTORY_ITEM_SUCCESS";
export const EDIT_INVENTORY_ITEM_REQUEST = "EDIT_INVENTORY_ITEM_REQUEST";
export const EDIT_INVENTORY_ITEM_SUCCESS = "EDIT_INVENTORY_ITEM_SUCCESS";
export const FETCH_INVENTORY_ITEM_SUCCESS = "FETCH_INVENTORY_ITEM_SUCCESS";
export const FETCH_INVENTORY_ITEMS_SUCCESS = "FETCH_INVENTORY_ITEMS_SUCCESS";
export const DELETE_INVENTORY_ITEM_REQUEST = "DELETE_INVENTORY_ITEM_REQUEST";
export const DELETE_INVENTORY_ITEM_SUCCESS = "DELETE_INVENTORY_ITEM_SUCCESS";

export const userConstants = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",

  DELETE_REQUEST: "USERS_DELETE_REQUEST",
  DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
  DELETE_FAILURE: "USERS_DELETE_FAILURE",
};

export const ItemTypes = { SEGMENT: "firing" };
